import React from "react";
import "../css/applyjob.css";

function Flutter() {
  window.scrollTo(0, 0);

  return (
    <div className="job-main">
      <div className="job-top">
        <div>
          <h3 className="job-title">Flutter Developer</h3>
        </div>
        <div>
          <a href="https://forms.gle/yBuhGJ5MxQpnhfGp8" className="job-apply">
            Apply for this job
          </a>
        </div>
      </div>
      <div className="job-body">
        <div className="about">
          <h4 className="job-sub-title">About Us</h4>
          <p className="job-content">
            Inter Deccaan Solutions was established with a vision of becoming a
            leading player in this field. We have both experienced and fresher
            across all the domains of Software Development. We have developed
            many custom made Web and Mobile applications for the client. Ours
            includes ReactJS, NodeJS, Kotlin, Python, PHP and Business
            Analytics. Our team works to meet all the various requirements made
            by the client.
          </p>
        </div>
        <div className="about-role">
          <h4 className="job-sub-title">About Role</h4>
          <p className="job-content">
            We are in search of Flutter Developer in Mobile app development to
            build and process high quality apps. You will be given a pportunity
            to solve interesting business and technical problems. We would offer
            you to work as the time mentioned per the Managment and expect you
            to perform the following.
          </p>
          <ul>
            <li>Analyse and work in existing project.</li>
            <li>Fix bugs in production websites.</li>
            <li>Build high quality websites.</li>
          </ul>
        </div>
        <div className="skills">
          <h4 className="job-sub-title">Skills Required</h4>
          <ul>
            <li> Good understanding and excellence in Flutter.</li>
            <li>Good problem solving skills.</li>
            <li>Good Knowledge about Bitbucket or GitHub.</li>
            <li>
              Experience in handling different Screen sizes(Customization for
              screens).
            </li>
            <li>Experience with Rest APIs integration.</li>
            <li> Ability to work along the teams in a efficient manner.</li>
            <li>
              Update all the latest technologies invented and to implement in
              the process of development in a efficient manner.
            </li>
            <li>Knowledge about unit test and test cases.</li>
            <li>Push notifications integration. .</li>
            <li>
              Understanding the problems that we are solving for the users.
            </li>
            <li>
              Good understanding about how the product/system works in the
              coding base.
            </li>
            <li>Understanding about App localization.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Flutter;
