import React from "react";
import { MdOutlineWeb } from "react-icons/md";
import { FiServer } from "react-icons/fi";
import { motion } from "framer-motion";
import "../css/webdev.css";
function WebDev() {
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <motion.div
      className="webdev"
      initial="out"
      animate="in"
      exit="out"
      variants={pageDurationTwo}
      transition={pageTransitionTwo}
    >
      <div className="web-dev-top-section">
        <div className="web-dev-main">
          <div className="web-img">
            <MdOutlineWeb />
          </div>
          <h3 className="web-title">Front End Development</h3>
          <p className="web-content">
            Unlike backend, Frontend development consists of web UI development
            using HTML/CSS and communicating with backend to get/put the data.
            Specifically, we are also specialised in developing single page
            applications using ReactJS, the new-age much demanded technology.
          </p>
        </div>
        <div className="web-dev-main">
          <div className="web-img-two">
            <FiServer />
          </div>
          <h3 className="web-title">Back End Development</h3>
          <p className="web-content">
            Backend development involves architecting the application, solving
            the business problem in a programmatic way to make ways for data
            transfer between frontend and database like REST APIs. We skillfully
            optimise every communication between frontend and database in a much
            secure way.
          </p>
        </div>
      </div>
      <div className="what-we-do">
        <h3 className="what-title">What we do</h3>
        <p className="what-content">
          Inter Deccaan is well renowned amongst the clients, as we believe that
          a successful website must project the main services or products that
          businesses offer and it should also attract the right kinds of
          visitors towards it. That is how we craft our clients’ websites based
          on the expectation they have.
        </p>
      </div>
    </motion.div>
  );
}
export default WebDev;
