import React from "react";
import { RiOpenSourceLine } from "react-icons/ri";
import { BiCustomize } from "react-icons/bi";
import { motion } from "framer-motion";
import "../css/ecommerce.css";
function Ecommerce() {
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <motion.div
      className="e-commerce"
      initial="out"
      animate="in"
      exit="out"
      variants={pageDurationTwo}
      transition={pageTransitionTwo}
    >
      <div className="comm-top-section">
        <div className="comm-main">
          <div className="comm-img">
            <RiOpenSourceLine />
          </div>
          <h3 className="comm-title">Open Source</h3>
          <p className="comm-content">
            Based on client’s requests, we also work in developing e commerce
            website and mobile applications with the existing open source
            ecommerce solutions like Spree, etc. Our developers, plan and
            integrate multiple commerce services skillfully so that the client's
            need is served without any hassle.
          </p>
        </div>
        <div className="comm-main">
          <div className="comm-img-two">
            <BiCustomize />
          </div>
          <h3 className="comm-title">Customization</h3>
          <p className="comm-content">
            As we prioritize your customization, we are known for tailoring the
            e commerce applications as first thing using Rails and Laravel- the
            much demanded market technology out there right now.
          </p>
        </div>
      </div>
      <div className="what-we-do">
        <h3 className="what-title">What we do</h3>
        <p className="what-content">
          We also develop e-commerce applications based on client’s needs. Our
          team has a wide experience in creating many e-commerce applications
          either from scratch or on top of client preferred services like Spree,
          etc,. We take pride in delivering our customers with more than
          expected marketplace platforms, with which they can readily integrate
          their service and start selling.
        </p>
      </div>
    </motion.div>
  );
}
export default Ecommerce;
