import React from "react";
import "../css/career.css";
import image2 from "../Assets/career.jpeg";
import image3 from "../Assets/career2.jpeg";
import image4 from "../Assets/career3.jpeg";
import image5 from "../Assets/career4.jpeg";
import career from "../Assets/career-main.jpg";
import { Link } from "react-router-dom";
function Career() {
  const data = [
    {
      title: "Flutter Developer",
      content: "Karur",
      component: "/flutter",
    },
    {
      title: "Front End Developer",
      content: "Karur",
      component: "/frontend",
    },
    {
      title: "Back End Developer",
      content: "Karur",
      component: "/backend",
    },
    {
      title: "Full Stack Developer",
      content: "Karur",
      component: "/fullstack",
    },
    {
      title: "UI / UX Designer",
      content: "Karur",
      component: "/ui",
    },
    {
      title: "Part Time Student Intern ",
      content: "Karur",
      component: "/parttime",
    },
    {
      title: "Full Time Student Intern ",
      content: "Karur",
      component: "/fulltime",
    },
  ];
  window.scrollTo(0, 0);

  return (
    <div className="career-main">
      <div className="career-home-img">
        <img src={career}></img>
        <h2 className="career-main-title">Career at IDS</h2>
        <p className="career-main-sub">
          Tech Park at your place. . Come and Join us . .
        </p>
      </div>
      <div className="career-top">
        <h3 className="career-title">
          <span>IDS </span>Culture
        </h3>
        <p className="career-content">
          Inter Deccan has created a very unique family culture in which the
          employees are given full freedom to work and a great flexibility when
          it comes in terms of the activates, celebrations and entertainment to
          create a beautiful Woking culture. We always wanted people to group
          with what they need..
        </p>
      </div>
      <div className="career-body">
        <div className="image-flex">
          <img src={image2}></img>
          <img src={image5}></img>
        </div>
        <div className="image-flex">
          <img src={image3}></img>
          <img src={image4}></img>
        </div>
      </div>
      <div className="openings-main">
        <h2 className="open-title">Current Openings</h2>
      </div>
      <div className="openings-flex">
        {data.map((item, index) => (
          <div key={index} className="opening-card">
            <div>
              <h3 className="open-sub-title">{item.title}</h3>
              <h4 className="open-sub-content">{item.content}</h4>
            </div>
            <div>
              <Link className="career-link" to={item.component}>
                Apply
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Career;
