import React from "react";
import "../css/contact.css";
import { HiMailOpen } from "react-icons/hi";
import { FaMobileAlt } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";

function Contact() {
  window.scrollTo(0, 0);
  return (
    <div className="contact">
      <div className="contact-left-flex">
        <h3 className="contact-title">Enquiry Form</h3>
        <div className="contact-form">
          <div className="contact-input">
            <label>Name</label>
            <br />
            <input type="text" placeholder="Ex. Elon Musk "></input>
          </div>
          <div className="contact-input">
            <label>Organization</label>
            <br />
            <input type="text" placeholder="Organization Name"></input>
          </div>
        </div>
        <div className="contact-form">
          <div className="contact-input">
            <label>Mobile</label>
            <br />
            <input type="number" placeholder="Mobile Number"></input>
          </div>
          <div className="contact-input">
            <label>Email</label>
            <br />
            <input type="email" placeholder="Email Address"></input>
          </div>
        </div>
        <div className="contact-form">
          <div className="contact-input">
            <label>I 'm looking for</label>
            <br />
            <select
              style={{
                height: "70px",
                width: "350px",
                marginTop: "15px",
                border: "none",
                outline: "none",
                font: "normal 16px/1 poppins",
                color: "gray",
                paddingLeft: "8px",
              }}
            >
              <option className="options">Business</option>
              <option className="options">Career</option>
            </select>
          </div>
          <div className="contact-input">
            <label>My Budget is</label>
            <br />
            <select
              style={{
                height: "70px",
                width: "350px",
                marginTop: "15px",
                border: "none",
                outline: "none",
                color: "gray",
                font: "normal 16px/1 poppins",
                paddingLeft: "8px",
              }}
            >
              <option>Select a Budget</option>
              <option>Less than $5K</option>
              <option>Between $5K to $10K</option>
              <option>Between $10K to $20K</option>
              <option>Between $20K to $50K</option>
              <option>Above $50K</option>
              <option>Not Sure</option>
            </select>
          </div>
        </div>
        <div className="description">
          <div>
            <label>Description</label>
            <br />
            <input type="text" placeholder="Description"></input>
          </div>
        </div>
        <div className="contact-form-btn">
          <button className="submit-btn">Submit</button>
        </div>
      </div>
      <div className="contact-right-flex">
        <h3 className="contact-title-two">Contact Details</h3>
        <div className="contact-data">
          <HiMailOpen className="contact-icon" />
          <h4>interdeccaansolutions@gmail.com</h4>
        </div>
        <div className="contact-data">
          <FaMobileAlt className="contact-icon" />
          <h4>6379555218</h4>
        </div>
        <div className="contact-data-address">
          <ImLocation2 className="contact-icon" />
          <h4>
            Door No : 39, Second Floor, Vaikkal Street ,
            <br /> Opposite Road to Selvakumar Hospital , Karur - 639001
          </h4>
        </div>
        <div className="map-section">
          <div className="contact-map-section">
            <h3 className="contact-title-two">View in Map</h3>
            <iframe
              width="100%"
              height="350"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=No.39%20Vaikkal%20Street,%20Karur%20-%20639001+(Inter%20DeccaaN)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
