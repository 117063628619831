import React from "react";
import "../css/applyjob.css";

function Backend() {
  window.scrollTo(0, 0);

  return (
    <div className="job-main">
      <div className="job-top">
        <div>
          <h3 className="job-title">Back End Developer</h3>
        </div>
        <div>
          <a href="https://forms.gle/yBuhGJ5MxQpnhfGp8" className="job-apply">
            Apply for this job
          </a>
        </div>
      </div>
      <div className="job-body">
        <div className="about">
          <h4 className="job-sub-title">About Us</h4>
          <p className="job-content">
            Inter Deccaan Solutions was established with a vision of becoming a
            leading player in this field. We have both experienced and fresher
            across all the domains of Software Development. We have developed
            many custom made Web and Mobile applications for the client. Ours
            includes ReactJS, NodeJS, Kotlin, Python, PHP and Business
            Analytics. Our team works to meet all the various requirements made
            by the client.
          </p>
        </div>
        <div className="about-role">
          <h4 className="job-sub-title">About Role</h4>
          <p className="job-content">
            We are in search of Back End Developer in Web app development to
            build and process high quality websites. You will be given a
            pportunity to solve interesting business and technical problems. We
            would offer you to work as the time mentioned per the Managment and
            expect you to perform the following.
          </p>
          <ul>
            <li>Analyse and work in existing project.</li>
            <li>Fix bugs in production websites.</li>
            <li>Build high quality websites.</li>
          </ul>
        </div>
        <div className="skills">
          <h4 className="job-sub-title">Skills Required</h4>
          <ul>
            <li>Good knowledge about Back-end Languages and Frameworks.</li>
            <li>Excellence in NodeJS or .net.</li>
            <li>Knowledge about PHP, Django.</li>
            <li>Understanding about Version Control.</li>
            <li>Understanding about Web hosting platforms.</li>
            <li>Knowledge about CDN servers.</li>
            <li>Knowledge about Google analytics.</li>
            <li>Technical skills.</li>
            <li>Problem solving and Analytical skills.</li>
            <li>Time management skiils.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Backend;
