import React from "react";
import { DiWindows } from "react-icons/di";
import { ImMobile2 } from "react-icons/im";
import { BsFillCartPlusFill } from "react-icons/bs";
import { IoLogoDesignernews } from "react-icons/io";
import { MdGroups } from "react-icons/md";
import { HiDocumentSearch } from "react-icons/hi";
import { motion } from "framer-motion";
import services from "../Assets/services2.jpg";
import { Link } from "react-router-dom";
import "../css/services.css";

function Services() {
  const data = [
    {
      icon: <DiWindows />,
      title: "Web Development",
      component: "/webdev",
      content:
        "Web development is the work involved in developing a website for the Internet (World Wide Web) or an intranet(a private network).Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services. ",
    },
    {
      icon: <ImMobile2 />,
      title: "Mobile App Development",
      component: "/mobileDev",
      content:
        "Mobile application development is the process to making software for smartphones and digital assistants, most commonly for Android and iOS. The software can be preinstalled on the device, downloaded from a mobile app store or accessed through a mobile. ",
    },
    {
      icon: <BsFillCartPlusFill />,
      title: "ECommerce",
      component: "/ecommerce",

      content:
        "E-commerce (electronic commerce) is the buying and selling of goods and services, or the transmitting of funds or data, over an electronic network, primarily the internet. These business transactions occur either as business-to-business (B2B), business-to-consumer (B2C) ",
    },
    {
      icon: <IoLogoDesignernews />,
      title: "UI / UX Design ",
      component: "/userinterface",

      content:
        "UI refers to the screens, buttons, toggles, icons, and other visual elements that you interact with when using a website, app, or other electronic device. UX refers to the entire interaction you have with a product and feel about the interaction.",
    },
    {
      icon: <MdGroups />,
      title: "Consultation ",
      component: "/consultation",

      content:
        "Throughout my years as a counselor educator, Consultative Process means an open, good faith dialogue among the appropriate individuals designated or identified by each Party on Breaches, disputes or issues of concern to or affecting the Collaborative.",
    },

    {
      icon: <HiDocumentSearch />,
      title: "BPO Services ",
      component: "/testing",

      content:
        "Outsource business processes to a third-party (external) company. The primary goal is to cut costs, free up time, and focus on core aspects of the business. Two types of BPO are front-office and back-office.",
    },
  ];
  const pageDuration = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };

  const pageTransition = {
    duration: 1,
  };
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: "20px",
    },
  };

  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <div className="services-main">
      <motion.div
        initial="out"
        animate="in"
        exit="out"
        variants={pageDurationTwo}
        transition={pageTransitionTwo}
      >
        <img className="service-main-img" src={services}></img>
        <h3 className="services-head">Services</h3>
      </motion.div>
      <motion.div
        className="services"
        initial="out"
        animate="in"
        exit="out"
        variants={pageDuration}
        transition={pageTransition}
      >
        {/* Map */}
        {data.map((item, index) => (
          <div key={index} className="service-card">
            <div className="web-logo-service">{item.icon}</div>
            <h3 className="card-title">{item.title}</h3>
            <p className="card-content">{item.content}</p>
            <div className="button-main">
              {item?.component ? (
                <Link to={item.component}>
                  <button className="card-button">Know More</button>
                </Link>
              ) : (
                <button className="card-button">Know More</button>
              )}
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
}
export default Services;
