import React from "react";
import "../css/React.css";
import { motion } from "framer-motion";

function ReactIntro() {
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <motion.div
      className="react-main"
      initial="out"
      animate="in"
      exit="out"
      variants={pageDurationTwo}
      transition={pageTransitionTwo}
    >
      <div className="title-head">
        <h3>Kotlin</h3>
      </div>

      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Introduction</h3>
          </div>
          <div className="content">
            <p>
              Kotlin is a modern, general-purpose programming language developed
              by JetBrains. Its full compatibility with Java and concise syntax
              makes it an appealing language for web development, Android
              development. Every year more people rely on mobile devices to meet
              their needs.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3>Why Kotlin</h3>
          </div>
          <div className="content">
            <p>
              Kotlin is an Android-compatible language that is concise,
              expressive, and designed to be type- and null-safe. It works with
              the Java language seamlessly, it makes easy for developers who
              love the Java language to keep using it but also incrementally add
              Kotlin code.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="imgContainer">
            <h3>Advantages</h3>
          </div>
          <div className="content">
            <p>
              If you want to facilitate the product maintenance or streamline
              custom Android app development, you can assign two or three
              engineers to move the project to Kotlin starting from a small part
              of the codebase.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Strong Community</h3>
          </div>
          <div className="content">
            <p>
              JetBrains is constantly improving the language. It’s committed to
              the project, has a large and highly competent team working on it,
              has a stable business model and is even converting parts of their
              own flagship product to use it.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3>Sefaety & Security</h3>
          </div>
          <div className="content">
            <p>
              The Kotlin developers integrated into the semantics the principles
              that prevent a variety of common mistakes usually happening during
              the program execution. Another reason for Kotlin’s higher-level
              safety (compared to Java) lies in its benefits.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Business Benifits</h3>
          </div>
          <div className="content">
            <p>
              Kotlin also facilitates faster development because it is much more
              concise — rough estimates show that it can cut as much as 40%
              lines of code compared to Java. Fewer lines of code = less time
              spent on coding = less money spent on development.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3>Adoption with Kotlin</h3>
          </div>
          <div className="content">
            <p>
              Google announced Kotlin as one of the officially supported
              languages for Android development. Among the reasons for choosing
              Kotlin, Google mentioned it is “concise, expressive, and designed
              to be type and null-safe”
            </p>
          </div>
        </div>
        {/* <div className="card ">
          <div className="imgContainer">
            <h3>Data Binding</h3>
          </div>
          <div className="content">
            <p>
              ReactJS trails one-way data binding. This means that absolutely
              anyone can track all the changes made to any particular segment of
              the data. This is a symbol of its simplicity.
            </p>
          </div>
        </div> */}
      </div>
    </motion.div>
  );
}
export default ReactIntro;
