import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Footer from "../src/Layout/Footer";
import Services from "./components/Services";
import Career from "./components/Career";
import Contact from "./components/Contact";
import Home from "./components/Home";
import MobileDev from "./components/MobileDev";
import WebDev from "./components/WebDev";
import UserInterface from "./components/UserInterface";
import Technologies from "./components/Technologies";
import ReactIntro from "./components/ReactIntro";
import ReactNativeIntro from "./components/ReactNativeIntro";
import NodeIntro from "./components/NodeIntro";
import KotlinIntro from "./components/KotlinIntro";
import PhythonIntro from "./components/PhythonIntro";
import Nav from "./Layout/Nav";
import Profile from "./components/Profile";
import Ecommerce from "./components/Ecommerce";
import Consultation from "./components/Consultation";
import Testing from "./components/Testing";
import Frontend from "./components/Frontend";
import Fullstack from "./components/Fullstack";
import Backend from "./components/Backend"
import Flutter from "./components/Flutter";
import Ui from "./components/Ui";
import Parttime from "./components/Parttime";
import Fulltime from "./components/Fulltime";

function Main() {
  return (
    <>
      <BrowserRouter>
        <Nav />
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/technologies" element={<Technologies />} />
            <Route path="/career" element={<Career />} />
            <Route path="/mobiledev" element={<MobileDev />} />
            <Route path="/webdev" element={<WebDev />} />
            <Route path="/userinterface" element={<UserInterface />} />
            <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/consultation" element={<Consultation />} />
            <Route path="/testing" element={<Testing />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/react" element={<ReactIntro />} />
            <Route path="/reactnative" element={<ReactNativeIntro />} />
            <Route path="/node" element={<NodeIntro />} />
            <Route path="/kotlin" element={<KotlinIntro />} />
            <Route path="/phython" element={<PhythonIntro />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/frontend" element={<Frontend />} />
            <Route path="/fullstack" element={<Fullstack />} />
            <Route path="/backend" element={<Backend />} />
            <Route path="/flutter" element={<Flutter />} />
            <Route path="/ui" element={<Ui />} />
            <Route path="/parttime" element={<Parttime />} />
            <Route path="/fulltime" element={<Fulltime />} />

          </Routes>
        </AnimatePresence>
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default Main;
