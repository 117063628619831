import React from "react";
import { motion } from "framer-motion";
import { GoDeviceMobile } from "react-icons/go";
import { GrAndroid } from "react-icons/gr";
import "../css/mobiledev.css";
function MobileDev() {
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <motion.div
      className="mobiledev"
      initial="out"
      animate="in"
      exit="out"
      variants={pageDurationTwo}
      transition={pageTransitionTwo}
    >
      <div className="mobile-dev-top-section">
        <div className="mobile-dev-main">
          <div className="mobile-img">
            <GoDeviceMobile />
          </div>
          <h3 className="mobile-title">iOS App Development</h3>
          <p className="mobile-content">
            We develop native iOS applications for iPhone and iPad using Swift
            and Objective-C programming languages. We also develop applications
            for Apple Watch (Watch OS).
          </p>
        </div>
        <div className="mobile-dev-main">
          <div className="mobile-img-two">
            <GrAndroid />
          </div>
          <h3 className="mobile-title">Android Development</h3>
          <p className="mobile-content">
            We develop native Android applications for Android phones, Android
            tablets, Android Watches using Java and Kotlin(much demanded)
            programming languages.
          </p>
        </div>
      </div>
      <div className="what-we-do">
        <h3 className="what-title">What we do</h3>
        <p className="what-content">
          While turning out the deals in business is must to sustain, engaging
          the customers is also equally important. We not only develop
          state-of-the-art mobile applications, but we also provide end-to-end
          support to our clients. When you want to have a mobile app for your
          business, we always understand your business requirements and help you
          in increasing the target audiences.
        </p>
      </div>
    </motion.div>
  );
}
export default MobileDev;
