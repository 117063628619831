import React from "react";
import "../css/applyjob.css";

function Ui() {
  window.scrollTo(0, 0);

  return (
    <div className="job-main">
      <div className="job-top">
        <div>
          <h3 className="job-title">UI / UX Designer</h3>
        </div>
        <div>
          <a href="https://forms.gle/yBuhGJ5MxQpnhfGp8" className="job-apply">
            Apply for this job
          </a>
        </div>
      </div>
      <div className="job-body">
        <div className="about">
          <h4 className="job-sub-title">About Us</h4>
          <p className="job-content">
            Inter Deccaan Solutions was established with a vision of becoming a
            leading player in this field. We have both experienced and fresher
            across all the domains of Software Development. We have developed
            many custom made Web and Mobile applications for the client. Ours
            includes ReactJS, NodeJS, Kotlin, Python, PHP and Business
            Analytics. Our team works to meet all the various requirements made
            by the client.
          </p>
        </div>
        <div className="about-role">
          <h4 className="job-sub-title">About Role</h4>
          <p className="job-content">
            We are in search of UI/UX designer in designing mobile apps and
            websites. You will be given a opportunity to solve interesting
            business and technical problems. We would offer you to work as the
            time mentioned per the Managment and expect you to perform the
            following.
          </p>
          <ul>
            <li>Analyse and work in existing project.</li>
            <li>Fix bugs in production websites.</li>
            <li>Build high quality websites.</li>
          </ul>
        </div>
        <div className="skills">
          <h4 className="job-sub-title">Skills Required</h4>
          <ul>
            <li>
              Knowledge about Prototyping, wireframing, user flows, mockups.
            </li>
            <li>Knowledge about Visual design and software.</li>
            <li> User research and usability testing.</li>
            <li>Communication and Presentation.</li>
            <li>Prioritization and time management.</li>
            <li>Collaboration</li>
            <li>Application development</li>
            <li>Information Architecture</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Ui;
