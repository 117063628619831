import React from "react";
import { motion } from "framer-motion";
import "../css/technologies.css";
import { FaReact } from "react-icons/fa";
import { IoLogoPython } from "react-icons/io";
import { SiKotlin } from "react-icons/si";
import { FaNodeJs } from "react-icons/fa";
import { SiSemanticuireact } from "react-icons/si";
import { Link } from "react-router-dom";

function Technologies() {
  const data = [
    {
      icon: <FaReact />,
      title: "React Js",
      component: "/react",
      content:
        "ReactJS is an amazing JavaScript framework that has made the development process much easier. Plus, it provides quality assured ReactJS applications with apt interfaces. ReactJS is considered as a much sought after alternative for the development of user-friendly and highly compelling websites & applications that provides a number of possibilities to the developers to make them more creative.",
    },
    {
      icon: <SiSemanticuireact />,
      title: "React-Native",
      component: "/reactnative",
      content:
        "React Native is a popular open source mobile application development framework created by Facebook. It uses JavaScript to create cross-platform mobile applications with true native capabilities. This means you can develop natively-rendered mobile apps for both iOS and Android simultaneously using a single codebase. This capability has made React Native one of the most popular app development framework.",
    },
    {
      icon: <FaNodeJs />,
      title: "Node Js",
      component: "/node",

      content:
        "Node.js is a cross-platform runtime environment, built on V8, high-performance open-source JavaScript engine. To ensure outstanding performance, Node.js applies event-driven, non-blocking I/O paradigm. In the past years, it gained a lot of popularity in various Node.js development circles. Node.js really shines in building fast, scalable network applications, offers benefits in performance, faster development.",
    },
    {
      icon: <SiKotlin />,
      title: "Kotlin ",
      component: "/kotlin",

      content:
        "Kotlin is a programming language for the Java Virtual Machine (JVM) and can, therefore, be used anywhere Java is used today (which is virtually anywhere). This includes server, client, web, and Android development. It’s developed by JetBrains who are currently working to bring Kotlin to embedded systems and iOS as well, potentially making it a one-stop language. ",
    },
    {
      icon: <IoLogoPython />,
      title: "Python ",
      component: "/phython",

      content:
        "Python is a general-purpose programming language that is interpreted and high-level. It focusses on readability and simple syntax. It has English like syntax and reading a python code is similar to reading an English sentence. Python offers all the functionalities one might ever need for programming tasks. Today, it is one of the most widely-used programming languages in the world. ",
    },
  ];
  const pageDuration = {
    in: {
      opacity: 1,
      x: "0",
    },
    out: {
      opacity: 0,
      x: "100%",
    },
  };
  const pageTransition = {
    duration: 1,
  };

  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };

  window.scrollTo(0, 0);

  return (
    <div className="technologies">
      <motion.div
        initial="out"
        animate="in"
        exit="out"
        variants={pageDurationTwo}
        transition={pageTransitionTwo}
        className="tech-head"
      >
        <h3>Technologies</h3>
        <p>
          We have expertise in all types of popular tech stack and offer the top
          trending technologies on demand. We are keen in providing our clients
          with the best possible technology which we always consider to be a
          part of a valued service.
        </p>
      </motion.div>
      <motion.div
        initial="out"
        animate="in"
        exit="out"
        variants={pageDuration}
        transition={pageTransition}
        className="tech"
      >
        {data.map((item, index) => (
          <div key={index} className="tech-card">
            <div className="web-logo">{item.icon}</div>
            <h3 className="card-title">{item.title}</h3>
            <p className="card-content">{item.content}</p>
            <div className="button-main">
              {item?.component ? (
                <Link to={item.component}>
                  <button className="card-button">Know More</button>
                </Link>
              ) : (
                <button className="card-button">Know More</button>
              )}
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
}
export default Technologies;
