import React from "react";
import { GiManualMeatGrinder } from "react-icons/gi";
import { SiBandrautomation } from "react-icons/si";
import { motion } from "framer-motion";
import "../css/testing.css";
function Testing() {
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <motion.div
      className="testing"
      initial="out"
      animate="in"
      exit="out"
      variants={pageDurationTwo}
      transition={pageTransitionTwo}
    >
      <div className="testing-top-section">
        <div className="testing-main">
          <div className="testing-img">
            <GiManualMeatGrinder />
          </div>
          <h3 className="testing-title">Logical Support</h3>
          <p className="testing-content">
            When it comes to consultations, we work to support our clients with
            all the logical ideas from our end, hoping for better outputs from
            their business profits. We also play a major role in providing
            support to clients on the problems being faced by them in their
            existing applications and solve it in no time.
          </p>
        </div>
        <div className="testing-main">
          <div className="testing-img-two">
            <SiBandrautomation />
          </div>
          <h3 className="testing-title">Technical Support</h3>
          <p className="testing-content">
            Not only in the logical side, we also support the clients in terms
            of technical areas to get them understand the current needs and the
            expected turn outs. Hence we show them the way on how the
            configurations and services should be made, when it comes to an
            application development
          </p>
        </div>
      </div>
      <div className="what-we-do">
        <h3 className="what-title">What we do</h3>
        <p className="what-content">
          We also develop e-commerce applications based on client’s needs. Our
          team has a wide experience in creating many e-commerce applications
          either from scratch or on top of client preferred services like Spree,
          etc,. We take pride in delivering our customers with more than
          expected marketplace platforms, with which they can readily integrate
          their service and start selling.
        </p>
      </div>
    </motion.div>
  );
}
export default Testing;
