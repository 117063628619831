import React from "react";
import "../css/React.css";
import { motion } from "framer-motion";

function ReactIntro() {
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <motion.div
      className="react-main"
      initial="out"
      animate="in"
      exit="out"
      variants={pageDurationTwo}
      transition={pageTransitionTwo}
    >
      <div className="title-head">
        <h3>Node JS</h3>
      </div>

      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Introduction</h3>
          </div>
          <div className="content">
            <p>
              Node js is a platform built on Chrome's JavaScript runtime for
              easily building fast and scalable network applications. Node js
              uses an event-driven, non-blocking I/O model that makes it
              lightweight and efficient, perfect for data-intensive real-time
              applications.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3>Why Node JS</h3>
          </div>
          <div className="content">
            <p>
              Node js is easily employed as a server-side proxy where it can
              handle a large amount of simultaneous connections in a
              non-blocking manner. It's especially useful for proxying different
              services with different response time
            </p>
          </div>
        </div>
        <div className="card">
          <div className="imgContainer">
            <h3>Advantages</h3>
          </div>
          <div className="content">
            <p>
              Manage local dependencies of project's tools. Manage
              globally-installed project's tools. Manage multiple versions of
              code and code dependencies. It is able to serve way more
              complicated applications than Ruby.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Scalability</h3>
          </div>
          <div className="content">
            <p>
              Node.js is a strong contender when it comes to the balance between
              performance and scaling. Instead of creating novel threads for
              each new connection in the RAM of a server, Node.js brings
              everything to one thread. In this way, up to a million connection
              is possible in one instance.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3>Extensibility</h3>
          </div>
          <div className="content">
            <p>
              Building anything on a barebone server is not optimal. You need to
              add some additional projects to promote your goals. In this
              regard, NPM is among the key advantages of Node.js. What is NPM?
              It stands for Node.js Package Manager. Node.js has more than 1
              million (data as of 2019).
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Availability</h3>
          </div>
          <div className="content">
            <p>
              Node.js advantages also extend to the overall costs of getting the
              platform. Node.js is open-source and free (as in freedom).
              Therefore, you can get the project whenever and wherever you want.
              Download Node.js from the official website and configure its basic
              extensions.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3> Resource - Efficiency</h3>
          </div>
          <div className="content">
            <p>
              it offers great performance and speed for the users. They don’t
              have to wait for some elements to load for a long time. As a
              result, resource efficiency grows. Indeed, Rust, Go, and C can be
              faster since those languages compile source codes into binaries,
              which normally execute much faster.
            </p>
          </div>
        </div>
        {/* <div className="card ">
          <div className="imgContainer">
            <h3>Data Binding</h3>
          </div>
          <div className="content">
            <p>
              ReactJS trails one-way data binding. This means that absolutely
              anyone can track all the changes made to any particular segment of
              the data. This is a symbol of its simplicity.
            </p>
          </div>
        </div> */}
      </div>
    </motion.div>
  );
}
export default ReactIntro;
