import React from "react";
import { Link } from "react-router-dom";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGoogleCircle } from "react-icons/ai";
import { HiMailOpen } from "react-icons/hi";
import { FaMobileAlt } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import "../css/footer.css";

function Footer() {
  return (
    <div className="footer-main">
      <div className="footer-flex">
        <div className="footer-left-flex">
          <h3 className="footer-company-name">Inter Deccaan Solutions</h3>
          <h6 className="company-profile">
            Inter Deccaan Solutions was established in the year 2022 with a
            vision of becoming a leading player in the field of Web Solutions &
            Software Development.
          </h6>
        </div>
        <div className="footer-middle-flex">
          <h2 className="company-name">Company</h2>
          <ul>
            <li>
              <IoIosArrowDroprightCircle className="footer-icon" />
              <Link to="/home">Home</Link>
            </li>
            <li>
              <IoIosArrowDroprightCircle className="footer-icon" />
              <Link to="/services">Services</Link>
            </li>
            <li>
              <IoIosArrowDroprightCircle className="footer-icon" />
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <IoIosArrowDroprightCircle className="footer-icon" />
              <Link to="/technologies">Technologies</Link>
            </li>
            <li>
              <IoIosArrowDroprightCircle className="footer-icon" />
              <Link to="/career">Career</Link>
            </li>
            <li>
              <IoIosArrowDroprightCircle className="footer-icon" />
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>

        {/* Contact Right Flex */}

        <div className="footer-right-flex">
          <h2 className="get-in-touch">Get in Touch</h2>
          <div className="contact-details">
            <HiMailOpen size="20px" className="social-icons" />
            <p className="footer-text">interdeccaansolutions@gmail.com</p>
          </div>
          <br />
          <div className="contact-details">
            <FaMobileAlt size="20px" className="social-icons" />
            <p className="footer-text">6379555218, 8300871233</p>
          </div>
          <div className="contact-details">
            <ImLocation2 size="20px" className="social-icons" />
            <p className="footer-text-address">
              Door No : 39, Second Floor, Vaikkal Street,
              <br />
              Opposite Road to Selvakumar Hospital, <br />
              Karur - 639001
            </p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <hr className="horizontal-line" />
        <div className="social-links">
          <div>
            <a
              href="https://www.facebook.com/profile.php?id=100089931602029"
              target="_blank"
            >
              <AiFillFacebook className="social-icons" />
            </a>
            <AiFillTwitterCircle className="social-icons" />
            <a
              href="https://www.instagram.com/inter_deccaan_solutions/?hl=en"
              target="_blank"
            >
              <AiFillInstagram className="social-icons" />
            </a>
            <a
              href="https://www.linkedin.com/company/82640006/admin/ "
              target="_blank"
            >
              <AiFillLinkedin className="social-icons" />
            </a>
            <a
              href="https://www.google.com/search?q=inter+deccaan+solution+in+karur&oq=inter+deccaan+solution+in+karur&aqs=chrome..69i57j46i10i13i175i199i512j0i10i13i30j0i390l3.9723j0j7&sourceid=chrome&ie=UTF-8"
              target="_blank"
            >
              <AiFillGoogleCircle className="social-icons" />
            </a>
          </div>
        </div>
        <p className="copyright">
          @Copyright. All rights reserved. Designed by
          <a href="http://interdeccaan.in/" target="-blank">
            {" "}
            Inter Deccaan Solutions
          </a>
        </p>
      </div>
    </div>
  );
}
export default Footer;
