import React from "react";
import { motion } from "framer-motion";
import { MdOutlineWeb } from "react-icons/md";
import { FiServer } from "react-icons/fi";
import "../css/userinterface.css";
function Userinterface() {
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);
  return (
    <motion.div
      className="userdev"
      initial="out"
      animate="in"
      exit="out"
      variants={pageDurationTwo}
      transition={pageTransitionTwo}
    >
      <div className="user-dev-top-section">
        <div className="user-dev-main">
          <div className="user-img">
            <MdOutlineWeb />
          </div>
          <h3 className="user-title"> User Experience</h3>
          <p className="user-content">
            We are conscious in providing the users with efficient User
            Experience (UX), so they can interact with the application much
            easily than ever. We work to enhance the customer satisfaction by
            and improving application’s usability using a pleasing interaction
            between the customer and the product.
          </p>
        </div>
        <div className="user-dev-main">
          <div className="user-img-two">
            <FiServer />
          </div>
          <h3 className="user-title">User Interface</h3>
          <p className="user-content">
            Like we play carefully with UXD, we also spend much time in building
            the right structure of the application with a level higher than our
            client’s expectation. That is how Mallow excel in UI and UX design
            which keeps on hitting higher margins day-by-day.
          </p>
        </div>
      </div>
      <div className="what-we-do">
        <h3 className="what-title">What we do</h3>
        <p className="what-content">
          We begin the creation of our ideas in a much traditional way, where we
          sketch the expected design as first thing. Later on, we scrutinise all
          the outputs into a single line by sorting each and every designs as
          per the requirements and functionality. Hence we achieve the most out
          of the suggested UI within the expected timeline.
        </p>
      </div>
    </motion.div>
  );
}
export default Userinterface;
