import React from "react";
import { SiWebassembly } from "react-icons/si";
import { IoMdAnalytics } from "react-icons/io";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { SiMaterialdesignicons } from "react-icons/si";
import { SiWebpack } from "react-icons/si";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { AiOutlineFlag } from "react-icons/ai";
import { SiSemanticuireact } from "react-icons/si";
import { GrReactjs } from "react-icons/gr";
import { FaNodeJs } from "react-icons/fa";
import { IoLogoPython } from "react-icons/io";
import { SiKotlin } from "react-icons/si";

import home from "../Assets/home.jpg";
import { motion } from "framer-motion";
import "../css/home.css";
import { Link } from "react-router-dom";
import Client from "./Client";
function Home() {
  const data = [
    {
      icon: <GrReactjs />,
      label: "React Js",
      component: "/react",
    },
    {
      icon: <SiSemanticuireact />,
      label: "React Native",
      component: "/reactnative",
    },
    {
      icon: <FaNodeJs />,
      label: "Node JS",
      component: "/node",
    },
    {
      icon: <IoLogoPython />,
      label: "Python",
      component: "/phython",
    },
    {
      icon: <SiKotlin />,
      label: "Kotlin",
      component: "/kotlin",
    },
  ];
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "3px",
    },
    out: {
      opacity: 0,
      y: 0,
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);
  return (
    <div className="Home">
      <motion.div
        initial="out"
        animate="in"
        exit="out"
        variants={pageDurationTwo}
        transition={pageTransitionTwo}
      >
        <img className="home-img" src={home}></img>
      </motion.div>
      <div className="home-head">
        <h3 className="home-title">Services</h3>
        <p>
          Right from web designing to providing end to end solutions, We work in
          a variety of domains like native mobile app, custom app development
          and offer an impressive service when it comes to business. By Keeping
          in mind that today is Significant, we lead shaping a better tomorrow.
        </p>
      </div>
      <div className="home-service-card">
        <div className="card-one">
          <Link className="links" to="/webdev" smooth>
            <SiWebassembly className="app-logo" />
            <h4 className="service-card-title">Web Development</h4>
            <p className="service-card-content">
              “Let us build and show the world your idea!” Building a website
              has never been...
            </p>
          </Link>
        </div>
        <div className="card-two">
          <Link className="links" to="/mobiledev" smooth>
            <MdOutlineMobileFriendly className="app-logo" />
            <h4 className="service-card-title">Mobile App Development</h4>
            <p className="service-card-content">
              Mobile application development is a prominent need for enhancing
              business. Keepin...
            </p>
          </Link>
        </div>
        <div className="card-three">
          <IoMdAnalytics className="app-logo" />
          <h4 className="service-card-title">BPO Services</h4>
          <p className="service-card-content">
            Solutions are the start point of every idea. By keeping this as the
            bottom line, w...
          </p>
        </div>
        <div className="card-four">
          <Link className="links" to="/userinterface" smooth>
            <SiMaterialdesignicons className="app-logo" />
            <h4 className="service-card-title">UI / UX Design</h4>
            <p className="service-card-content">
              Solutions are the start point of every idea. By keeping this as
              the bottom line, w...
            </p>
          </Link>
        </div>
      </div>
      <div className="project-client-section">
        <div className="project-card-one">
          <SiWebpack className="project-logo" />
          <div>
            <h4 className="project-card-content">10 +</h4>
            <p>Projects</p>
          </div>
        </div>
        <div className="project-card-one">
          <BsFillPersonCheckFill className="project-logo" />
          <div>
            <h4 className="project-card-content">15 +</h4>
            <p>Clients</p>
          </div>
        </div>
        <div className="project-card-one">
          <HiUserGroup className="project-logo" />
          <div>
            <h4 className="project-card-content">20 +</h4>
            <p>Team Size</p>
          </div>
        </div>
        <div className="project-card-one">
          <AiOutlineFlag className="project-logo" />
          <div>
            <h4 className="project-card-content">2022</h4>
            <p>Established</p>
          </div>
        </div>
      </div>
      <Client />
      <div className="tech-home-main">
        <div className="home-head">
          <h3 className="home-title">Technologies</h3>
          <p>
            We have expertise in all types of popular tech stack and offer the
            top trending technologies on demand. We are keen in providing our
            clients with the best possible technology which we always consider
            to be a part of a valued service.
          </p>
        </div>
        <div className="tech-content">
          {data.map((item, index) => (
            <Link className="links" to={item.component}>
              <div key={index} className="tech-card-one">
                <div className="tech-content-logo">{item.icon}</div>
                <h4>{item.label}</h4>
              </div>
            </Link>
          ))}
        </div>

        <div className="tech-btn">
          <Link to="/technologies">
            <button className="tech-view">View Brief</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Home;
