import React from "react";
import "../css/client.css";
import image1 from "../Assets/arm-logo.png";
import image2 from "../Assets/soft-logo.jpeg";
import image3 from "../Assets/selvan-logo.png";
import image4 from "../Assets/anand-logo.jpg";
import image5 from "../Assets/jk-logo2.png";
import image6 from "../Assets/alpha.png";
// import { Link } from "react-router-dom";

function Client() {
  const data = [
    {
      icon: image1,
      title: "A. R. M. Texttiles",
      component: "http://www.armtex.in/",
      content:
        " Arumugam Ramasamy Mudaliyar Texttiles is one of the leading exporters of cotton home furnishing textiles.",
    },
    {
      icon: image2,
      title: "Softline Exports",
      component: "http://thesoftline.com/",
      content:
        " “SOFTLINE” Textile manufacturers and exporters of home textile are dedicated to  customers  products.",
    },
    {
      icon: image3,
      title: "Selvan Tex",
      component: "http://selvantex.in/",
      content:
        " Selvan Tex Located at Karur is one of the major Textiles manufacturing and exporter in South India.",
    },
    {
      icon: image4,
      title: "Anand Traders",
      component: "http://www.anandtrader.in/",
      content:
        " Anand Traders Located at Karur is one of the major Textiles manufacturing and exporter in India.",
    },
    {
      icon: image5,
      title: "JK Fashion",
      component: "https://jkfashion.co/",
      content:
        " The company has enjoyed steady growth based upon quality and customer satisfaction. ",
    },
    {
      icon: image6,
      title: "Alpha Solutions",
      component: "https://thealpha.co.in/",
      content:
        " Our business was founded in 2021 and has been operating successfully since then. we use highly powerful AI and KPO techniques. ",
    },
  ];
  return (
    <div className="clients">
      <h3 className="client-title">Our Clients</h3>
      <div className="client-main">
        {data.map((item, index) => (
          <div key={index} className="client-card">
            <div>
              <img src={item.icon} />
              <h3>{item.title}</h3>
              <p>{item.content}</p>
              <button className="client-button">
                <a href={item.component} target="-blank">
                  Visit Site
                </a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Client;
