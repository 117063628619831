import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/Logo.png";
import "../css/Nav.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="Navbar">
      <div className="nav-top-content">
        <div>
          <Link to="/home">
            <img className="nav-logo-image" src={logo}></img>
          </Link>
        </div>
        <div className="nav-bottom">
          <br />
        </div>
      </div>
      <div className={`nav-items ${isOpen && "open"}`}>
        <Link to="/home">Home</Link>
        <Link to="/services">Services</Link>
        <Link to="/profile">Profile</Link>
        <Link to="/technologies">Technologies</Link>
        <Link to="/career">Career</Link>

        <Link to="/contact">
          <button Link to="/contact" className="contact-btn">
            Contact Us
          </button>
        </Link>
      </div>
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Navbar;
