import React from "react";
import "../css/Profile.css";
import { motion } from "framer-motion";
import background from "../Assets/profile.jpg";
import image1 from "../Assets/arm-logo.png";
import image2 from "../Assets/soft-logo.jpeg";
import image3 from "../Assets/selvan-logo.png";
import image4 from "../Assets/anand-logo.jpg";
import image5 from "../Assets/jk-logo2.png";
import image6 from "../Assets/alpha.png";

function Profile() {
  const data = [
    {
      title: "A.R.M Texttiles",
      content:
        "  Arumugam Ramasamy Mudaliyar Texttiles is one of the leading exporters of cotton home furnishing textiles. We have created a niche as manufacturers and exports of an exquisite and exclusive range of home furnishings goods ranging from Bed Linen, Bath Linen, Table Linen and Kitchen Linen.",
      icon: image1,
      component: "http://www.armtex.in/",
    },
    {
      title: "Softline Exports",
      content:
        " “SOFTLINE” Textile manufacturers and exporters of home textile are dedicated to helping customers in providing products and services on time, and to enhance the customers satisfaction by means of devotion, quality and value. Here at “SOFTLINE” we engage effectively, responsibly in textiles.",
      icon: image2,
      component: "http://www.armtex.in/",
    },
    {
      title: "Anand Traders",
      content:
        "  Anand Traders Located at Karur is one of the major Textiles manufacturing and exporter especially home furnishing textiles in South India. Our primary focus remains timely delivery to our clients at all times.",
      icon: image4,
      component: "http://www.anandtrader.in/",
    },
  ];

  const list = [
    {
      title: "JK Fashions",
      content:
        " “We recognise that the very basis of smooth functioning is the art infrastructure. With sufficient floorspace, modern machinery and contemporary facilities, and a highly motivated work force is the very foundation of our success.",
      icon: image5,
      component: "https://jkfashion.co/",
    },
    {
      title: "Selvan Tex",
      content:
        " Selvan Tex Located at Karur is one of the major Textiles manufacturing and exporter especially home furnishing textiles in South India. Their primary focus remains timely delivery to our clients at all times. A products from Selvan traders comes with the promise of quality workmenship. They are proud to offer a fantastic and varieties of table linen and kitchen textiles. ",
      icon: image3,
      component: "http://selvantex.in/",
    },
    {
      title: "Alpha Solutions",
      content:
        "  Our business was founded in 2021 and has been operating successfully since then. we use highly powerful AI and KPO techniques.Additionaly experienced staff members are performing and leading as it comes naturally to them.",
      icon: image6,
      component: "http://www.anandtrader.in/",
    },
  ];

  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <div className="profile">
      <motion.div
        initial="out"
        animate="in"
        exit="out"
        variants={pageDurationTwo}
        transition={pageTransitionTwo}
      >
        <img className="profile-backdrop" src={background} />
      </motion.div>
      <div className="profile-main">
        <div className="profile-left-flex">
          <div>
            <h3>Our Projects</h3>
            <p>
              We are expertise in making the business websites, our team has
              successfully build lots of commercial websites aroud our city. Our
              aim is to make our clients happy and increase their business by
              the websites. Our major clients are listed below
            </p>
          </div>
        </div>
        <div className="profile-right-flex">
          {data.map((item, index) => (
            <div key={index} className="project-card">
              <div className="project-image">
                <img src={item.icon}></img>
              </div>
              <h3>{item.title}</h3>
              <p>{item.content}</p>
              <div className="button-project">
                <button className="project-button">
                  <a href={item.component} target="-blank">
                    Visit Site
                  </a>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="project-second-section">
        {list.map((item, index) => (
          <div key={index} className="project-card">
            <div className="project-image">
              <img src={item.icon}></img>
            </div>
            <h3>{item.title}</h3>
            <p>{item.content}</p>
            <div className="button-project">
              <button className="project-button">
                <a href={item.component} target="-blank">
                  Visit Site
                </a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Profile;
