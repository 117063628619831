import React from "react";
import "../css/React.css";
import { motion } from "framer-motion";

function ReactIntro() {
  const pageDurationTwo = {
    in: {
      opacity: 1,
      y: "0",
    },
    out: {
      opacity: 0,
      y: "100%",
    },
  };
  const pageTransitionTwo = {
    duration: 1,
  };
  window.scrollTo(0, 0);

  return (
    <motion.div
      className="react-main"
      initial="out"
      animate="in"
      exit="out"
      variants={pageDurationTwo}
      transition={pageTransitionTwo}
    >
      <div className="title-head">
        <h3>Python</h3>
      </div>

      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Introduction</h3>
          </div>
          <div className="content">
            <p>
              Python is a dynamic, interpreted (bytecode-compiled) language.
              There are no type declarations of variables, parameters,
              functions, or methods in source code. This makes the code short
              and flexible, and you lose the compile-time type checking of the
              source code.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3>Why Python</h3>
          </div>
          <div className="content">
            <p>
              Python is commonly used for developing websites and software, task
              automation, data analysis, and data visualization. Since it's
              relatively easy to learn, Python has been adopted by many
              non-programmers such as accountants and scientists, for a variety
              of everyday tasks.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="imgContainer">
            <h3>Advantages</h3>
          </div>
          <div className="content">
            <p>
              Java is a statically typed and compiled language, and Python is a
              dynamically typed and interpreted language. This single difference
              makes Java faster at runtime and easier to debug, but Python is
              easier to use and easier to read.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Data Science</h3>
          </div>
          <div className="content">
            <p>
              You are not required to be a software developer to use Python.
              Python facilitates data analysis and visualization. The rich and
              efficient libraries allow data processing. It helps data
              scientists to perform complex numeric computing operations.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3>Easy to Use</h3>
          </div>
          <div className="content">
            <p>
              Programmers say that Python is easy to use. Although when
              constructing mobile applications or games C++ or any other typical
              scripting language might be easier to use, Python is better for
              easily building server-side applications, automating build systems
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card  ">
          <div className="imgContainer">
            <h3>Object - Oriented</h3>
          </div>
          <div className="content">
            <p>
              Python supports object-oriented programming, and it is
              procedure-oriented. Object-Oriented Programming, in the sense, it
              utilizes objects that are based on data and functionality. The
              Procedure Oriented feature offers to apply reusable pieces of
              code.
            </p>
          </div>
        </div>
        <div className="card ">
          <div className="imgContainer">
            <h3>Wide Application</h3>
          </div>
          <div className="content">
            <p>
              Python language can be used for wide applications. It is
              extensively used by scientists, engineers, and mathematicians for
              all kinds of applications. Python is also useful for prototyping
              all kinds of experiments. It is used in many groundbreaking
              fields, such as animation for movies.
            </p>
          </div>
        </div>
        {/* <div className="card ">
          <div className="imgContainer">
            <h3>Data Binding</h3>
          </div>
          <div className="content">
            <p>
              ReactJS trails one-way data binding. This means that absolutely
              anyone can track all the changes made to any particular segment of
              the data. This is a symbol of its simplicity.
            </p>
          </div>
        </div> */}
      </div>
    </motion.div>
  );
}
export default ReactIntro;
